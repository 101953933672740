.video--wrapper
  .video--video
    @apply opacity-0
  &.is-playing
    .video--video
      @apply opacity-100
    .video--play-trigger
      @apply top-[calc(100%-4em)] left-[calc(100%-4em)] text-xs
      // @apply opacity-0
  // &.is-playing:hover
  //   .video--play-trigger
  //     // @apply opacity-100
  //     // @apply opacity-100
