.editor
  p,
  ol,
  ul
    @apply mb-[1em]
    &:last-child
      @apply mb-0



.editor-sm
  @apply font-mono text-sm_copy
  ul li
    @apply pl-4 md:pl-8
    @apply relative mb-0 before:content-['→'] before:absolute before:left-0
    p
      @apply last:mb-0
  a
    @apply font-sans
    @apply before:content-['→'] before:absolute before:-translate-x-[140%] before:translate-y-[10%]
    @apply before:transition-transform hover:before:-rotate-45
    @apply ml-[1.3em] no-underline
    @apply whitespace-nowrap

.editor-sm h1
  @apply text-sm_copy

.editor-sm h1,
.editor-sm h2,
.editor-sm h3
  @apply text-sm_copy font-sans uppercase tracking-wider

.editor-sm h2,
.editor-sm h3
  @apply mt-[2.5em]

.editor-sm h1,
.editor-sm h2,
.editor-sm h3,
.editor-sm p
  @apply mb-[0.5em]



.editor-md
  @apply text-lg- font-sans tracking-wide
.editor-lg
  @apply text-lg font-sans tracking-wide
.editor-md:not(.text-splitted),
.editor-lg:not(.text-splitted)
  a
    @apply before:content-['→'] before:absolute before:-translate-x-[130%] lg:before:-translate-x-[110%]
    @apply before:transition-transform hover:before:-rotate-45
    @apply ml-[1.2em] lg:ml-[1em] underline underline-offset-[2px] md:underline-offset-4 lg:underline-offset-[6px] decoration-2 md:decoration-2 lg:decoration-4
    @apply whitespace-nowrap

.editor-md.text-splitted,
.editor-lg.text-splitted
  a
    @apply inline-block mr-[0.25em] ml-[1.25em] lg:ml-[1.1em]
  a div
    @apply before:content-['→'] before:absolute before:-translate-x-[130%] lg:before:-translate-x-[110%]
    @apply before:transition-transform hover:before:-rotate-45
    @apply underline underline-offset-[3px] md:underline-offset-4 lg:underline-offset-[5px] decoration-2 md:decoration-2 lg:decoration-4
    @apply whitespace-nowrap
