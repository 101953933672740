@charset "UTF-8";
@font-face {
  font-family: "LabGrotesque";
  src: url("/assets/fonts/LabGrotesque-Bold.woff2") format("woff2"), url("/assets/fonts/LabGrotesque-Bold.woff") format("woff");
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "LabGrotesqueMono";
  src: url("/assets/fonts/LabGrotesqueMono-Regular.woff2") format("woff2"), url("/assets/fonts/LabGrotesqueMono-Regular.woff") format("woff");
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Faxi";
  src: url("/assets/fonts/Faxi-Regular.woff2") format("woff2"), url("/assets/fonts/Faxi-Regular.woff") format("woff");
  font-style: normal;
  font-display: block;
}
:root {
  --vh: 100vh;
  --c-primary: #263365;
  --c-secondary: #399FD4;
  --c-neutral: #414141;
  --c-neutral-light: #969696;
}

@tailwind base;
@layer base {
  html.lenis,
  html.lenis body {
    height: auto;
  }
  .lenis.lenis-smooth {
    scroll-behavior: auto !important;
  }
  .lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
  }
  .lenis.lenis-stopped {
    overflow: hidden;
  }
  .lenis.lenis-smooth iframe {
    pointer-events: none;
  }
}
@tailwind components;
@layer components {
  .button {
    @apply block md:inline-block  min-w-[300px] text-center;
    @apply py-[0.65em] pl-8 pr-12 rounded-full border border-theme-copy text-theme-copy;
    @apply text-sm font-mono uppercase tracking-widest;
    @apply relative after:content-["→"] after:absolute after:right-[1em];
    @apply transition-colors duration-300 hover:bg-theme-black hover:text-theme-white;
  }
  .inverted .button {
    @apply border-theme-white text-theme-white;
    @apply hover:bg-theme-white hover:text-black;
  }
  #logo-load .logo-load-path {
    stroke-width: 0.1px !important;
  }
  @screen md {
    #logo-load .logo-load-path {
      stroke-width: 0.25px !important;
    }
  }
}
@tailwind utilities;
@layer utilities {
  .editor p {
    @apply mb-[1em];
  }
  .editor a {
    @apply hover:underline;
  }
  .mask-clip-default {
    clip-path: polygon(0 0, 100% 0, 100% 50svh, 100% 100svh, 0 100svh, 0 50svh);
  }
  .mask-clip {
    clip-path: polygon(0 0, 100% 0, 90% 50svh, 100% 100svh, 0 100svh, 10% 50svh);
  }
  .fold-headline br {
    @apply hidden md:block;
  }
}
.is-active .ac-header .accordion-trigger-icon {
  @apply -rotate-180;
}
.ac-header:focus, .ac-header::-moz-focus-inner {
  border: none;
  outline: none !important;
}

.ac-text {
  @apply py-0;
}

.base-bigtarget {
  @apply before:content-[""] before:absolute before:top-0 before:right-0 before:bottom-0 before:left-0;
}
.base-bigtarget-expanded {
  @apply before:content-[""] before:absolute before:-top-[1000rem] before:-right-[1000rem] before:-bottom-[1000rem] before:-left-[1000rem];
}

.editor p,
.editor ol,
.editor ul {
  @apply mb-[1em];
}
.editor p:last-child,
.editor ol:last-child,
.editor ul:last-child {
  @apply mb-0;
}

.editor-sm {
  @apply font-mono text-sm_copy;
}
.editor-sm ul li {
  @apply pl-4 md:pl-8;
  @apply relative mb-0 before:content-["→"] before:absolute before:left-0;
}
.editor-sm ul li p {
  @apply last:mb-0;
}
.editor-sm a {
  @apply font-sans;
  @apply before:content-["→"] before:absolute before:-translate-x-[140%] before:translate-y-[10%];
  @apply before:transition-transform hover:before:-rotate-45;
  @apply ml-[1.3em] no-underline;
  @apply whitespace-nowrap;
}

.editor-sm h1 {
  @apply text-sm_copy;
}

.editor-sm h1,
.editor-sm h2,
.editor-sm h3 {
  @apply text-sm_copy font-sans uppercase tracking-wider;
}

.editor-sm h2,
.editor-sm h3 {
  @apply mt-[2.5em];
}

.editor-sm h1,
.editor-sm h2,
.editor-sm h3,
.editor-sm p {
  @apply mb-[0.5em];
}

.editor-md {
  @apply text-lg- font-sans tracking-wide;
}

.editor-lg {
  @apply text-lg font-sans tracking-wide;
}

.editor-md:not(.text-splitted) a,
.editor-lg:not(.text-splitted) a {
  @apply before:content-["→"] before:absolute before:-translate-x-[130%] lg:before:-translate-x-[110%];
  @apply before:transition-transform hover:before:-rotate-45;
  @apply ml-[1.2em] lg:ml-[1em] underline underline-offset-[2px] md:underline-offset-4 lg:underline-offset-[6px] decoration-2 md:decoration-2 lg:decoration-4;
  @apply whitespace-nowrap;
}

.editor-md.text-splitted a,
.editor-lg.text-splitted a {
  @apply inline-block mr-[0.25em] ml-[1.25em] lg:ml-[1.1em];
}
.editor-md.text-splitted a div,
.editor-lg.text-splitted a div {
  @apply before:content-["→"] before:absolute before:-translate-x-[130%] lg:before:-translate-x-[110%];
  @apply before:transition-transform hover:before:-rotate-45;
  @apply underline underline-offset-[3px] md:underline-offset-4 lg:underline-offset-[5px] decoration-2 md:decoration-2 lg:decoration-4;
  @apply whitespace-nowrap;
}

.img-full img,
.img-full picture,
.img-full svg {
  @apply block w-full h-auto;
}
.img-full-h img,
.img-full-h picture,
.img-full-h svg {
  @apply block w-full h-full mx-auto;
}

.navigation--trigger-deco-01, .navigation--trigger-deco-02, .navigation--trigger-deco-03, .navigation--trigger-deco-04 {
  @apply content-[""];
  @apply block w-5 md:w-7 h-[3px] md:h-1;
  @apply absolute top-1/2 left-1/2 -translate-x-1/2;
  @apply transition-all duration-200 origin-center;
  @apply bg-current;
}
.navigation--trigger-deco-01 {
  @apply rotate-0;
  @apply -translate-y-2 md:-translate-y-2.5;
}
.navigation--trigger-deco-02 {
  @apply rotate-0;
  @apply translate-y-2 md:translate-y-2.5;
}
.navigation--trigger-deco-03 {
  @apply rotate-0;
}
.navigation--trigger-deco-04 {
  @apply rotate-0;
}
.navigation__active .navigation--trigger-deco-01 {
  @apply opacity-0 -translate-y-4;
}
.navigation__active .navigation--trigger-deco-02 {
  @apply opacity-0 translate-y-4;
}
.navigation__active .navigation--trigger-deco-03 {
  @apply rotate-45;
}
.navigation__active .navigation--trigger-deco-04 {
  @apply -rotate-45;
}

.reveal {
  @apply opacity-0 transition-opacity duration-1000 ease-in-out;
}
.reveal.reveal__show, .reveal__show .reveal {
  @apply opacity-100;
}

.video--wrapper .video--video {
  @apply opacity-0;
}
.video--wrapper.is-playing .video--video {
  @apply opacity-100;
}
.video--wrapper.is-playing .video--play-trigger {
  @apply top-[calc(100%-4em)] left-[calc(100%-4em)] text-xs;
}