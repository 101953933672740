.ac-header
  .is-active &
    .accordion-trigger-icon
      @apply -rotate-180

  &:focus,
  &::-moz-focus-inner
    border: none
    outline: none !important

.ac-text
  // @apply pt-3 lg:pt-6
  // @apply pb-6 lg:pb-12
  @apply py-0
