@layer utilities {
  .editor {
    p {
      @apply mb-[1em];
    }
    a {
      @apply hover:underline;
    }
  }

  .mask-clip-default {
    clip-path: polygon(0 0, 100% 0, 100% 50svh, 100% 100svh, 0 100svh, 0 50svh);
  }
  .mask-clip {
    clip-path: polygon(0 0, 100% 0, 90% 50svh, 100% 100svh, 0 100svh, 10% 50svh);
  }

  .fold-headline {
    br {
      @apply hidden md:block
    }
  }
}
