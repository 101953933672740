.img
  &-full
    img,
    picture,
    svg
      @apply block w-full h-auto

  &-full-h
    img,
    picture,
    svg
      @apply block w-full h-full mx-auto
