@layer components {
  .button {
    @apply block md:inline-block  min-w-[300px] text-center;
    @apply py-[0.65em] pl-8 pr-12 rounded-full border border-theme-copy text-theme-copy;
    @apply text-sm font-mono uppercase tracking-widest;
    @apply relative after:content-['→'] after:absolute after:right-[1em];
    @apply transition-colors duration-300 hover:bg-theme-black hover:text-theme-white;

    .inverted & {
      @apply  border-theme-white text-theme-white;
      @apply hover:bg-theme-white hover:text-black;
    }
  }

  #logo-load {
    .logo-load-path {
      stroke-width: 0.1px !important;
      @screen md {
        stroke-width: 0.25px !important;
      }
    }
  }
}
