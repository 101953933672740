@font-face {
  font-family: "LabGrotesque";
  src: url("/assets/fonts/LabGrotesque-Bold.woff2") format("woff2"), url("/assets/fonts/LabGrotesque-Bold.woff") format("woff");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "LabGrotesqueMono";
  src: url("/assets/fonts/LabGrotesqueMono-Regular.woff2") format("woff2"), url("/assets/fonts/LabGrotesqueMono-Regular.woff") format("woff");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Faxi";
  src: url("/assets/fonts/Faxi-Regular.woff2") format("woff2"), url("/assets/fonts/Faxi-Regular.woff") format("woff");
  font-style: normal;
  font-display: block;
}
